<template>
  <router-view/>
</template>

<script>
  document.querySelector('link[rel="icon"]').setAttribute('href', './favicon_1684194460.ico')
</script>

<style>

</style>
