import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';

Vue.use(Vuex);

/**
 * 初始化 Vuex
 *
 * @type {Store<{}>}
 */
const store = new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {

    user, // 用户中心模块

  },
});

export default store;
