import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/userAgreement/:lang',
    name: 'UserAgreement',
    component: () => import('../views/Wallet/UserAgreement.vue')
  },
  {
    path: '/agreement/:lang',
    name: 'Agreement',
    component: () => import('../views/Wallet/Agreement.vue')
  },
  {
    path: '/keystore/:lang',
    name: 'Keystore',
    component: () => import('../views/Wallet/Keystore.vue')
  },
  {
    path: '/mnemonic/:lang',
    name: 'Mnemonic',
    component: () => import('../views/Wallet/Mnemonic.vue')
  },
  {
    path: '/privateKey/:lang',
    name: 'PrivateKey',
    component: () => import('../views/Wallet/PrivateKey.vue')
  },
  {
    path: '/identityWallet/:lang',
    name: 'IdentityWallet',
    component: () => import('../views/Wallet/IdentityWallet.vue')
  },
  {
    path: '/versionLog/:lang',
    name: 'VersionLog',
    component: () => import('../views/Wallet/VersionLog.vue')
  },
  {
    path: '/touchID/:lang',
    name: 'touchID',
    component: resolve => require(['../views/Wallet/TouchID.vue'], resolve)
  },
  {
    path: '/register',
    name: 'register',
    component: resolve => require(['../views/login/Register'], resolve),
  },
  {
    path: '/download',
    name: 'download',
    component: resolve => require(['../views/login/Download'], resolve),
  },
]

const router = new VueRouter({
  routes
})

export default router
