import Cookies from 'js-cookie';

// 初始化 state
const state = {

    // token
    token: Cookies.get('token'),

    // 用户信息
    user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : '',
};

// mutations
const mutations = {

    // 用户登录设置 token
    login(state, token) {
        state.token = token;
        Cookies.set('token', token);
    },

    // 设置用户信息
    updateProfile(state, user) {
        state.user = user;
        Cookies.set('user', JSON.stringify(user));
    },

    // 用户退出
    logout(state) {
        state.token = '';
        state.user = '';
        Cookies.remove('token');
        Cookies.remove('user');
    },
};

// actions
const actions = {

    // 用户登录设置token
    login: ({commit}, token) => commit('login', token),

    // 设置用户信息
    updateProfile: ({commit}, user) => commit('updateProfile', user),

    // 用户退出
    logout: ({commit}) => commit('logout'),
};

export default {
    state,

    mutations,

    actions,
};
