import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
// 主题 css
import './theme/index.less'
// 自定义 css
import './styles/index.less'
// 多语言
import VueI18n from 'vue-i18n';
import en from 'view-design/dist/locale/en-US';
import zh from 'view-design/dist/locale/zh-CN';
import zh_TW from 'view-design/dist/locale/zh-TW';
import enLocales from './locales/en-US';
import zhLocales from './locales/zh-CN';
import zhTWLocales from './locales/zh-TW';

import less from 'less'
Vue.use(less);

Vue.use(VueI18n);
Vue.use(ViewUI,{
  i18n: function(path, options) {
    let value = i18n.t(path, options);
    if (value !== null && value !== undefined) {
      return value
    }
    return ''
  }
});

Vue.locale = () => {
};

const messages = {
  en: Object.assign(enLocales, en),
  zh_CN: Object.assign(zhLocales, zh),
  zh_TW: Object.assign(zhTWLocales, zh_TW)
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh_CN',
  messages
});

import './css/index.less'

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
